body {
  margin: 0;
  font-family: "notoSerif", "Pretendard", "HancomHoonminjeongeumH", -apple-system, BlinkMacSystemFont, "KimjungchulMyungjo", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", "notoSerif", monospace;
}
