@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Pretendard"), url("./Assets/Fonts/Pretendard-Black.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Pretendard"), url("./Assets/Fonts/Pretendard-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Pretendard"), url("./Assets/Fonts/Pretendard-Bold.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Pretendard"), url("./Assets/Fonts/Pretendard-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Pretendard"), url("./Assets/Fonts/Pretendard-Medium.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Pretendard"), url("./Assets/Fonts/Pretendard-Regular.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Pretendard"), url("./Assets/Fonts/Pretendard-Light.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local("Pretendard"), url("./Assets/Fonts/Pretendard-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("Pretendard"), url("./Assets/Fonts/Pretendard-Thin.woff") format("woff");
}

/* @font-face {
  font-family: "KimjungchulMyungjo-Bold";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("KimjungchulMyungjo-Bold"), url("./Assets/Fonts/KimjungchulMyungjo-Bold.otf") format("otf");
} */

@font-face {
  font-family: "NotoSerif";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local("NotoSerif"), url("./Assets/Fonts/NotoSerifKR-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "NotoSerif";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("NotoSerif"), url("./Assets/Fonts/NotoSerifKR-Light.woff") format("woff");
}

@font-face {
  font-family: "NotoSerif";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("NotoSerif"), url("./Assets/Fonts/NotoSerifKR-Regular.woff") format("woff");
}

@font-face {
  font-family: "NotoSerif";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("NotoSerif"), url("./Assets/Fonts/NotoSerifKR-Medium.woff") format("woff");
}

@font-face {
  font-family: "NotoSerif";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("NotoSerif"), url("./Assets/Fonts/NotoSerifKR-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "NotoSerif";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("NotoSerif"), url("./Assets/Fonts/NotoSerifKR-Bold.woff") format("woff");
}

@font-face {
  font-family: "NotoSerif";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("NotoSerif"), url("./Assets/Fonts/NotoSerifKR-Black.woff") format("woff");
}

@font-face {
  font-family: "Serif";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local("Serif"), url("./Assets/Fonts/NotoSerifKR-ExtraLight.otf") format("otf");
}

@font-face {
  font-family: "Serif";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Serif"), url("./Assets/Fonts/NotoSerifKR-Light.otf") format("otf");
}

@font-face {
  font-family: "Serif";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Serif"), url("./Assets/Fonts/NotoSerifKR-Regular.otf") format("otf");
}

@font-face {
  font-family: "Serif";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Serif"), url("./Assets/Fonts/NotoSerifKR-Medium.otf") format("otf");
}

@font-face {
  font-family: "Serif";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Serif"), url("./Assets/Fonts/NotoSerifKR-SemiBold.otf") format("otf");
}

@font-face {
  font-family: "Serif";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Serif"), url("./Assets/Fonts/NotoSerifKR-Bold.otf") format("otf");
}

@font-face {
  font-family: "Serif";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Serif"), url("./Assets/Fonts/NotoSerifKR-Black.otf") format("otf");
}

@font-face {
  font-family: "HancomHoonminjeongeumH";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/2406-1@1.0/HancomHoonminjeongeumH.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "KimjungchulMyungjo-Bold";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2302_01@1.0/KimjungchulMyungjo-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "NotoSerif", "Serif", "Pretendard", "NanumMyeongjo", "NanumMyeongjoBold", "NanumMyeongjoExtraBold", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-Serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "NotoSerif", "Serif", "Pretendard", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
