@media screen and (max-width: 767px) {
  input::placeholder {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }

  textarea::placeholder {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .Flex_App {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }

  .FlexContent_Login {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100vh;
  }

  .FlexContent_Other {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
  }

  .AllContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Required_Dot_Red {
    width: 6px;
    height: 6px;
    background-color: rgb(216, 44, 44);
    border-radius: 3px;
    margin-left: 4px;
  }

  .Required_Dot_Blue {
    width: 6px;
    height: 6px;
    background-color: rgb(55, 105, 252);
    border-radius: 3px;
    margin-left: 4px;
  }

  .GNB_BG {
    display: flex;
    flex-direction: row;
    position: fixed;
    z-index: 3;
    box-shadow: none;
    top: 0px;
    width: 100vw;
    height: 48px;
    background-color: rgb(255, 255, 255);
    justify-content: space-between;
    align-items: center;
  }

  .GNB_LeftContents {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    margin-left: 5vw;
  }

  .GNB_Label_Underline {
    display: flex;
    z-index: 1;
    text-decoration: none;
  }

  .GNB_Logo {
    display: flex;
    z-index: 1;
    width: 18px;
    height: auto;
  }

  .GNB_CompanyProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
  }

  .GNB_CompanyLogo {
    display: flex;
    z-index: 1;
    width: 20px;
    height: 20px;
    border-radius: 12px;
    overflow: hidden;
  }

  .GNB_CompanyName_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;

    margin-left: 8px;
  }

  .GNB_SettingIcon_CT {
    display: flex;
    z-index: 1;
    margin-left: 6px;
    width: 20px;
    height: 20px;
    display: none;
  }

  .GNB_RightContents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-right: 5vw;
  }

  .GNB_Logout_CT {
    display: flex;
  }

  .GNB_Profile_CT {
    display: flex;
    margin-left: 24px;
  }

  .GNB_Logout_Button {
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    border: none;
  }

  .Home_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Login_Head_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    letter-spacing: -3%;

    margin-top: 160px;
    margin-bottom: 24px;
  }

  .Login_Auto_Copy {
    color: rgb(121, 121, 121);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -3%;

    margin-left: 2px;
  }

  .Login_TextButton_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -3%;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0px;
    padding: 0px;
  }

  .Login_VerticalLine {
    display: flex;
    width: 1px;
    height: 16px;
    background-color: rgb(170, 170, 170);
    margin-left: 4px;
    margin-right: 4px;
  }

  .Login_Input_BG {
    display: flex;
    flex-direction: row;
    width: 304px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;

    margin-top: 12px;
    outline: none;
  }

  .LoginButton_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 228px;
    height: 64px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
  }

  .Login_Button_One_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 12px;
  }

  .LoginButton_One_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 320px;
    height: 44px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
  }

  .LoginButton_Copy_CT {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .LoginButton_Copy {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
  }

  .Login_TextButton_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    width: 320px;
  }

  .Login_RightContents_CT {
    display: flex;
    flex-direction: row;
  }

  .Login_SigninButton_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Login_SigninButton {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 48px;
  }

  .Login_Signin_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Login_SigninButton_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;

    margin-left: 4px;
  }

  .Login_Auto_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Signin_Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 320px;
    margin-top: 24px;
  }

  .Signin_InputField_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
  }

  .Signin_ShortInput_BG {
    display: flex;
    flex-direction: row;
    width: 434px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .Signin_TextArea_BG {
    display: flex;
    flex-direction: row;
    width: 302px;
    height: 96px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 4px;
    resize: none;
    outline: none;
  }

  .Signin_LongInput_BG_None {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 4px;
    outline: none;
  }

  .Signin_LongInput_BG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 302px;
    height: 32px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 4px;
    outline: none;
  }

  .Signin_Line {
    display: flex;
    flex-direction: row;
    width: 320px;
    height: 2px;
    background-color: rgb(227, 227, 227);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .Signin_CompleteModal_BG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Signin_CompleteModal_CT {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    width: 360px;
    border-radius: 12px;
  }

  .Signin_CompleteModal_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: -3%;
    margin-top: 16px;
  }

  .Signin_CompleteModal_Button_CT {
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .Signin_CompleteModal_Button {
    margin: 0px;
    padding: 0px;
    border: none;
    text-decoration: none;
    background-color: rgb(55, 105, 252);
    border-radius: 12px;
    width: 128px;
    height: 48px;
    cursor: pointer;
  }

  .Signin_CompleteModal_Button_Copy {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .TabBar_CT {
    display: flex;
    flex-direction: column;

    width: 90vw;
    max-width: 1024px;
    margin-bottom: 16px;
    margin-top: 72px;
  }

  .TabBar_Labels_CT {
    display: flex;
    flex-direction: row;
    z-index: 1;
  }

  .TabBar_Label_CT {
    display: flex;
    flex-direction: row;
    z-index: 1;
    text-decoration: none;
    margin-right: 32px;
    cursor: pointer; /* 커서 모양 변경 (선택사항) */
  }

  .TabBar_Label_Underline {
    display: flex;
    z-index: 1;
    text-decoration: none;
    margin: 0px;
    padding: 0px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .TabBar_Label_Copy_A {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -3%;
  }

  .TabBar_Label_Copy_B {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -3%;
  }

  .TabBar_Line {
    display: flex;
    flex-direction: row;
    width: 90vw;
    max-width: 1024px;
    height: 2px;
    background-color: rgb(227, 227, 227);
    margin-top: 12px;
  }

  .DetailTitle_CT {
    display: flex;
    flex-direction: column;

    width: 320px;
    max-width: 1024px;
    /* margin-bottom: 32px; */
    margin-top: 72px;
  }

  .DetailTitle_Labels_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .DetailTitle_Label_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .DetailTitle_Label_Underline {
    display: flex;
    text-decoration: none;
    background-color: transparent;
    margin: 0px;
    padding: 0px;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
  }

  .DetailTitle_Icon_CT {
    display: flex;
    flex-direction: row;
    width: 20px;
    height: 20px;
  }

  .DetailTitle_Icon_Profile_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-left: 4px;
    /* margin-bottom: 4px; */
  }

  .DetailTitle_Label_Copy_A {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .DetailTitle_Label_Copy_B {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .DetailTitle_Line {
    display: flex;
    flex-direction: row;
    width: 320px;
    height: 2px;
    background-color: rgb(227, 227, 227);
    margin-top: 24px;
  }

  .ListCard_BG {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    width: 90vw;
    max-width: 1024px;
    height: 48px;
    border-style: solid;
    border-radius: 12px;
    border-width: 1px;
    border-color: rgb(148, 186, 253);
    background-color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .ListCard_Box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .ListCard_Label_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
  }

  .ListCard_Body_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;

    margin-top: 2px;
  }

  .ListCard_Box_CT {
    display: flex;
    margin-right: 24px;
  }

  .ListCard_Box_CT_First {
    display: flex;
    margin-left: 12px;
    margin-right: 12px;
  }

  .ListCard_Box_CT_Last {
    display: flex;
    margin-right: 12px;
  }

  .ListCard_LeftContents {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .ListCard_RightContents {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .Wait_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1024px;
  }

  .Wait_Lists_CT {
    display: flex;
    flex-direction: column;
    height: 468px;
  }

  .Wait_NoneItem_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 120px;
    margin-bottom: 584px;
  }

  .DetailWait_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    max-width: 1024px;
  }

  .Wait_Pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }

  .Wait_Pagination_Active_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    height: 32px;
  }

  .Wait_Pagination_Inactive_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    height: 32px;
    padding: 0px;
  }

  .Wait_Pagination_Style {
    display: flex;
    list-style-type: none;
    float: left;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 32px;
  }

  .Wait_PaginationCT_Style {
    display: flex;
    list-style-type: none;
    float: left;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 32px;
  }

  .Detail_Title_BG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 320px;
    max-width: 1024px;
    background-color: rgb(246, 246, 246);
    height: 64px;
  }

  .Detail_Title_Contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .Detail_Title_LeftContents {
    display: flex;
    flex-direction: row;
    margin-left: 12px;
  }

  .Detail_Title_RightContents {
    display: flex;
    flex-direction: row;
    margin-right: 12px;
  }

  .Detail_Title_ContentBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 16px;
  }

  .Detail_Title_ContentBox_Last {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Detail_Title_Label_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: -3%;
    margin-bottom: 4px;
  }

  .Detail_Title_Content_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -3%;
  }

  .CertificateCard_BG {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 16px;
    width: 320px;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin-top: 24px;
  }

  .CertificateCard_BG_Complete {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 16px;
    width: 320px;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin-top: 24px;
    margin-bottom: 64px;
  }

  .CertificateCard_BG_Reject {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 16px;
    width: 320px;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .CertificateCard_Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 12px;
    margin-bottom: 8px;
  }

  .CertificateCard_Body_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .CertificateCard_SecondText_CT {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .CertificateCard_Label_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -3%;
    margin-left: 12px;
  }

  .CertificateCard_BodyLeft {
    display: flex;
    flex-direction: row;
  }

  .CertificateCard_BodyLeft_C {
    display: flex;
    flex-direction: column;
  }

  .CertificateCard_Body_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -3%;
    margin-left: 12px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .CertificateCard_SecondBody_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -3%;
    margin-left: 12px;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  .CertificateCard_DetailBody_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: -3%;
    margin-left: 8px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .CertificateCard_Icon_CT {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    cursor: pointer;
  }

  .CertificateCard_Line {
    display: flex;
    flex-direction: row;
    width: 94%;
    height: 1px;
    background-color: rgb(227, 227, 227);
  }

  .DetailWait_Button_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 320px;
    margin-top: 24px;
    margin-bottom: 64px;
  }

  .DetailWait_Button_One_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 64px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    padding: 0px;
  }

  .PrimayButton_One_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 320px;
    height: 52px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
    border: none;
  }

  .PrimayButton_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 152px;
    height: 52px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
  }

  .SubButton_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-radius: 12px;
    border-color: rgb(55, 105, 252);
    border-width: 1px;
    width: 228px;
    height: 64px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;

    margin-top: 24px;
    margin-right: 24px;
  }

  .DestroyButton_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 152px;
    height: 52px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 1px;
    border-color: rgb(255, 115, 115);
    cursor: pointer;
  }

  .PrimayButton_Copy {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }

  .SubButton_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
  }

  .DestroyButton_Copy {
    color: rgb(216, 44, 44);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }

  .RejectInputBox_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 12px;
    width: 296px;
    height: 270px;
    background-color: rgb(255, 255, 255);
    margin-top: 24px;
    padding: 12px;

    resize: none;
    outline: none;
  }

  .RejectReason_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Reject_Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .RejectReason_Label_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -3%;

    display: flex;
    margin-bottom: 8px;

    width: 320px;
  }

  .RejectReason_Body_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;

    display: flex;
    margin-bottom: 64px;
    width: 320px;
  }

  .Profile_Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 320px;
    margin-top: 24px;
  }

  .Profile_Subject {
    display: flex;
    flex-direction: column;
    width: 320px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 16px;
  }

  .Profile_Subject_Last {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 320px;
  }

  .ProfileDetail_Subject {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 16px;
    width: 320px;
  }

  .ProfileDetail_Subject_Last {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 320px;
  }

  .Profile_Label_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
  }

  .Profile_Label_Icon_Logo_CT {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0px;
  }

  .Profile_Label_Icon_License_CT {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    cursor: pointer;
  }

  .Profile_Label_Icon_Seal_CT {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0px;
  }

  .Profile_Label_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

  .Profile_Body_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    width: 320px;
  }

  .Profile_NoImage_Copy {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .Profile_Placeholder_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
  }

  .Profile_Line {
    display: flex;
    flex-direction: row;
    width: 320px;
    height: 2px;
    background-color: rgb(227, 227, 227);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .Profile_CloseModal_Button_CT {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .Profile_CloseModal_Button {
    margin: 0px;
    padding: 0px;
    border: none;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
  }

  .Profile_CloseModal_Contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .ProfileDetail_ShortInput_BG {
    display: flex;
    flex-direction: row;
    width: 170px;
    height: 32px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .ProfileDetail_LongInput_BG {
    display: flex;
    flex-direction: row;
    width: 624px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    padding-left: 8px;
    padding-right: 8px;
  }

  .ProfileDetail_LongInput_BG_Icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 640px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
  }

  .ProfileDetail_LongInput_None {
    display: flex;
    flex-direction: row;
    border: none;
    width: 624px;
    height: 40px;
    background-color: transparent;
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .ProfileDetail_AddressDetail_CT {
    display: flex;
    align-items: center;
    margin-top: 12px;
  }

  .ProfileDetail_TextArea_BG {
    display: flex;
    flex-direction: row;
    width: 624px;
    height: 96px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
    resize: none;
  }

  .ProfileDetail_UploadButton_CT {
    display: flex;
    justify-content: row;
    margin-bottom: 12px;
    margin-top: 4px;
  }

  .ProfileDetail_UploadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 36px;
    border-radius: 8px;
    border-width: 0px;
    background-color: rgb(231, 239, 255);

    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    margin-left: 12px;
    cursor: pointer;
    padding: 0px;
  }

  .ProfileDetail_UploadButton_None {
    display: none;
  }

  .ProfileDetail_Image {
    width: 320px;
  }

  .ImgModal_CT {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    padding-top: 240px;
    width: 100vw;
    height: auto;
  }

  .ImgModal_Button {
    display: flex;
    flex-direction: column;
    width: 32px;
    height: 32px;
    margin-left: 8px;
    cursor: pointer;
  }

  .Icon_CT {
    display: flex;
    flex-direction: column;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    cursor: pointer;
  }

  .PdfModal_CT {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    padding-top: 240px;
    width: 100vw;
    height: auto;
  }

  .PdfModal_Close {
    display: flex;
    flex-direction: column;
    width: 32px;
    height: 32px;
    margin-left: 8px;
    cursor: pointer;
  }

  .Footer_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 128px;
    margin-bottom: 24px;
    width: 320px;
    max-width: 1024px;
  }

  .Footer_Button {
    display: flex;
    margin: 0px;
    padding: 0px;
    border: none;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
  }

  .Footer_Logo {
    display: flex;
    width: 79.5px;
    height: 18px;
  }

  .Footer_Texts {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
  }

  .Footer_Text {
    display: flex;
    margin-bottom: 4px;
  }

  .Footer_Text_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    justify-content: center;
  }

  .Signin_Modal_BG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Profile_Modal_BG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
  }

  .Modal_Contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .Modal_Icon {
    display: flex;
    flex-direction: column;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    text-decoration: none;
  }

  .Signin_Password_Contents {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Signin_PasswordInvalid_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
    margin-top: 8px;
  }

  .Signin_Error_Copy {
    color: rgb(255, 115, 115);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
    margin-top: 8px;
  }

  .Signin_Match_Copy {
    color: rgb(3, 166, 0);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
    margin-top: 8px;
  }

  .Signin_Guide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 320px;
  }

  .Signin_Guide_TextButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    /* text-decoration: none; */
    cursor: pointer;

    margin-top: 8px;
  }

  .Signin_Guide_TextButton_Icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
  }

  .Signin_Guide_TextButton_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
  }

  .Signin_IdEmail_Num {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .TermLists_BG {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 318px;
    height: auto;

    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 8px;
    margin-top: 4px;
    padding-bottom: 8px;
  }

  .TermLists_Line {
    width: 296px;
    height: 1px;
    background-color: rgb(227, 227, 227);
  }

  .TermLists_Line2 {
    width: 296px;
    height: 1px;
    background-color: rgb(227, 227, 227);
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .TermList_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 320px;
    height: 36px;
  }

  .TermList_Two_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 318px;
    height: 48px;
  }

  .TermList_Two_CT_Issue {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
  }

  .TermList_Left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 12px;
    margin-right: 8px;
  }

  .TermList_Right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 12px;
  }

  .TermList_Default_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    width: 240;
  }

  .TermList_Optional_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
    margin-right: 2px;
    width: 30px;
  }

  .TermList_TwoLine_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .TermList_Red_Copy {
    color: rgb(255, 115, 115);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
    margin-right: 2px;
    width: 32px;
  }

  .TermList_Detail_CT {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 4px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .TermList_Detail_Icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
  }

  .TermList_Detail_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
    width: 42px;
  }

  .TermList_CheckBox_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .AllContent_Between {
    display: flex;
    flex: 1;
    width: 100vw;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 48px;

    background-color: rgb(242, 244, 246);
  }

  .AllContent_Between_Detail {
    display: flex;
    flex: 1;
    width: 100vw;
    /* height: 100vh; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 48px;

    background-color: rgb(255, 255, 255);
  }

  .RequestCardCT_Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 80vw;
    height: auto;
    background-color: rgb(255, 255, 255);
    padding: 16px;
    border-style: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 12px;
  }

  .RequestCardCT_LeftTop_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .RequestCardCT_LeftBottom_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 11px;
  }

  .RequestCardCT_Left_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* background-color: aquamarine; */
    width: 60vw;
    margin-right: 12px;
  }

  .RequestCardCT_Right_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .RequestCardCT_Name_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
  }

  .RequestCardCT_Memo_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 16px;
    background-color: white;
    border-style: solid;
    border-color: rgb(170, 170, 170);
    border-radius: 2px;
    cursor: pointer;
    margin-left: 6px;
  }

  .RequestCardCT_Memo_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
  }

  .RequestCardCT_CompName_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    letter-spacing: -3%;
  }

  .RequestCardCT_Date_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
  }

  .RequestList_Body_Status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background-color: rgb(0, 0, 0);
    width: 58px;
    height: 20px;
  }

  .RequestList_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
  }

  .Issue_Seal_Modal_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
  }

  .Issue_Error_BG {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    background-color: rgb(255, 255, 255);
  }

  .Issue_Error_Text_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .Reject_Reason_BG {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 90%;
    /* max-width: 360px; */
    height: auto;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(170, 170, 170);
    border-radius: 8px;
    padding: 12px;
  }

  .Reject_Reason_TextArea {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 92%;
    height: 240px;
    border-width: 1px;
    border-color: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 12px;
    resize: none;

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Email_RightArea_Buttons_CT {
    display: flex;
    flex-direction: row;
    margin-top: 32px;
  }

  .Seal_Image_Modal {
    width: auto;
    max-height: 400px;
  }

  .Email_Right_Button_BG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 48px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(55, 105, 252);
    border-radius: 8px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
    margin-left: 16px;

    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
  }

  .RequestListCT_Button_S {
    margin: 0px;
    padding: 0px;
    width: 72px;
    height: 36px;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    border-color: rgb(170, 170, 170);
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .RequestListCT_Button_L {
    margin: 0px;
    padding: 0px;
    width: 120px;
    height: 36px;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    border-color: rgb(170, 170, 170);
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .RequestList_Button_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }

  .RequestList_TopButton_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-top: 16px;
  }

  .Issue_Error_Button {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 24px;
  }

  .Email_Left_Button_BG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 48px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(255, 155, 155);
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;

    color: rgb(216, 44, 44);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
  }
  .RequestDetail_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 90%;
    background-color: rgb(170, 170, 170);
    margin-top: 16px;
    margin-bottom: 20px;
  }

  .RequestList_DetailLabel_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
  }
  .RequestList_DetailBody_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    margin-top: 5px;
  }
  .RequestList_MidContents_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }
  .RequestList_BottomContents_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    margin-top: 24px;
  }

  .RequestList_Item_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    /* background-color: antiquewhite; */
  }

  .RequestList_ItemText_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    word-break: break-all;
  }

  .RequestList_VerticalLine {
    height: 41px;
    width: 1px;
    background-color: rgb(170, 170, 170);
  }

  .RequestList_ContactNumber_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
  }

  .RequestListCT_Button_Icon {
    display: flex;
    margin: 0px;
    padding: 0px;
    width: 32px;
    height: 32px;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    border-color: rgb(170, 170, 170);
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  .RequestList_ContactNumber_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }

  .RequestList_Note_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    /* background-color: blueviolet; */
    width: 90vw;
  }

  .RequestList_CTA_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 48px;
  }

  .RequestList_CTA_CT2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: -24px;
    margin-left: 24px;
    margin-bottom: 48px;
  }

  .RequestListCT_CTAButton_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 0px;
    padding: 0px;
    width: 160px;
    height: 48px;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    border-color: rgb(170, 170, 170);
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .RequestListCT_IconButton {
    margin: 0px;
    padding: 0px;
    width: 24px;
    height: 24px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 8px;
  }

  .Email_Button {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 48px;
  }

  .Email_Button_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Email_Button_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    letter-spacing: -3%;
  }

  .Email_RightArea_Buttons_CT2 {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
  }

  .Email_Sub_Error_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
    margin-bottom: 4px;
  }

  .TermLists_Short_BG {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 288px;
    height: auto;

    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 8px;

    padding-bottom: 8px;
  }

  .TermLists_Short_BG_Issue {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;

    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 8px;

    padding-bottom: 8px;
  }

  .TermLists_Short_Line {
    width: 240px;
    height: 1px;
    margin-bottom: 12px;
    background-color: rgb(227, 227, 227);
  }

  .Email_Sub_ETC_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
    margin-top: 16px;
  }

  .Reject_Reason_TextArea2 {
    display: flex;
    width: 90%;
    height: 72px;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 8px;
    padding: 12px;
    resize: none;
    margin-top: 12px;

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .TermList_Short_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 288px;
    height: 32px;
  }

  .TermList_Short_CT_Issue {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
  }

  .TermList_Two_Short_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 288px;
    height: 48px;
  }

  .TermList_Two_Short_CT_Issue {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
  }

  .Email_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 90%;
    margin-top: 24px;
  }

  .Email_CT_Issue {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-top: 24px;
  }

  .Email_LeftArea_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    /* margin-top: 96px;
    height: 788px; */
  }

  .Email_LeftContents_CT {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .Email_LeftContents_CT_Reject {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .Email_Main_Copy_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 220px;
  }
  .Email_Main_1_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    letter-spacing: -3%;
  }

  .Email_Main_2_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: -3%;
  }

  .Email_Main_1_Copy_CT {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 4px;
  }

  .Email_Sub_Copy_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* max-width: 210px; */
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .Email_Sub_1_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_Sub_1_Copy2 {
    color: rgb(130, 130, 130);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    letter-spacing: -3%;
    line-height: 130%;
    margin-top: 8px;
  }

  .Read_AllContent_Between {
    display: flex;
    flex: 1;
    width: 100vw;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 48px;

    background-color: rgb(255, 255, 255);
  }

  .Read_RightArea_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
    height: auto;
    width: 100%;
    margin-bottom: 24px;
  }

  .Read_Labels_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .Read_Label_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }

  .Read_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 100%;
    background-color: rgb(170, 170, 170);
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .Email_Block_All_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
  }

  .Email_Block_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .Email_Block_Icon_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 240px;
    margin-bottom: 24px;
  }

  .Read_All_Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
  }

  .Read_Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .Read_Item_Text_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 335px;
    margin-right: 24px;
    /* background-color: aqua; */
  }

  .Read_Item_Type_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: left;

    margin-bottom: 4px;
  }

  .Read_Item_Name_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: left;
  }

  .Read_Item_Date_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;

    margin-top: 8px;
  }

  .Read_Item_Positions_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;

    margin-top: 4px;
  }

  .Read_Item_Jobs_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;

    margin-top: 4px;
  }

  .Read_Button {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-top: 12px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .Read_Right_Button_Short_BG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 32px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(231, 239, 255);
    border-radius: 8px;
    background-color: rgb(231, 239, 255);
    cursor: pointer;
    /* margin-left: 16px; */

    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }

  .Read_Item_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 100%;
    background-color: rgb(227, 227, 227);
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .Email_InfoBox_BG_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* margin-top: 36px; */
  }

  .Email_InfoBox_BG_CT2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 24px;
  }

  .Email_InfoBox_BG {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 90%;
    height: auto;
    border-style: solid;
    border-radius: 12px;
    border-width: 1px;
    border-color: rgb(246, 250, 255);
    background-color: rgb(246, 250, 255);
    padding: 16px;
  }

  .Email_InfoBox_1_Copy_CT {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .Email_InfoBox_1_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -3%;
  }

  .Email_InfoBox_2_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_LawInfo_Copy_CT {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    width: 90%;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding-left: 24px;
    padding-right: 24px;
  }

  .Email_LawInfo_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_LawProfile_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .Email_LawProfile_Left_CT {
    display: flex;
    flex-direction: row;
    margin-right: 24px;
  }

  .Email_LawProfile_Right_CT {
    display: flex;
    flex-direction: column;
  }

  .Email_LawProfile_Intro_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .Email_LawProfile_Company_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_LawProfile_Name_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_LawProfile_Vertical_CT {
    width: 1px;
    height: 16px;
    background-color: rgb(170, 170, 170);
    margin-left: 8px;
    margin-right: 8px;
  }

  .Email_LawProfile_Detail_CT {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }

  .Email_LawProfile_Tel_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_PDF_BG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* width: 336px;
    height: 475px; */
    width: 86%;
    height: auto;
    background-color: rgb(255, 255, 255);
    padding: 24px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  .Email_PDF_Upper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Email_PDF_Lower {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Email_PDF_CompanyHead_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .Email_CompanyHead_Input_BG {
    display: flex;
    flex-direction: row;
    width: 116px;
    height: 32px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(55, 105, 252);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .Email_PDF_IssueNumber_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjo";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    letter-spacing: -3%;
    width: 100%;
  }

  .Email_PDF_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    word-spacing: 16px;
    margin-top: 12px;
  }

  .Email_PDF_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    margin-top: 32px;
  }

  .Email_PDF_Date_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    margin-top: 12px;
  }

  .Email_PDF_CompanyName_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 12px;
  }

  .Email_PDF_CompanyHead_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-right: 4px;
  }

  .Email_PDF_Chart_BG {
    width: 100%;
    height: auto;
    border-style: solid;
    border-width: 0.5px;
    border-color: rgb(70, 70, 70);
    background-color: rgb(255, 255, 255);
    margin-top: 24px;
  }

  .Email_PDF_Cell_Label {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    text-align: center;

    width: 35px;
    padding: 4px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(242, 244, 246);
  }

  .Email_PDF_Cell_Label_2 {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    text-align: center;
    word-spacing: 12px;

    width: 32px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 4px;
    padding-right: 4px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(242, 244, 246);
  }

  .Email_PDF_Cell_Label_3 {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    text-align: center;
    word-spacing: 1.7px;

    width: 38px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 4px;
    padding-right: 4px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(242, 244, 246);
  }

  .Email_PDF_Cell_Body {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    text-align: left;
    line-height: 150%;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 4px;
    padding-right: 4px;
    border: 1px solid rgb(70, 70, 70);
  }

  .Email_PDF_SealNone_BG {
    display: flex;
    position: absolute;
    top: 640px;
    right: 160px;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-style: dashed;
    border-width: 3px;
    border-color: rgb(148, 186, 253);
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
    opacity: 0.8;
    cursor: pointer;
  }

  .Email_PDF_SealNone_BG_M {
    display: flex;
    position: absolute;
    top: 630px;
    right: 40px;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-style: dashed;
    border-width: 3px;
    border-color: rgb(148, 186, 253);
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
    opacity: 0.8;
    cursor: pointer;
  }

  .Email_PDF_SealDone_BG {
    display: flex;
    position: absolute;
    top: 640px;
    right: 200px;
    align-items: flex-start;
    justify-content: flex-start;

    cursor: pointer;
  }

  .Email_PDF_SealDone_BG_M {
    display: flex;
    position: absolute;
    top: 660px;
    right: 50px;
    align-items: flex-start;
    justify-content: flex-start;

    cursor: pointer;
  }

  .Email_PDF_SealDone_Close_CT {
    display: flex;
    flex-direction: row;
    margin-left: 4px;

    border: none;
    background-color: transparent;
    padding: 0px;
  }

  .Email_PDF_Seal_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    text-align: center;
  }

  .Email_RightArea_Body_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 24px;
  }

  .Email_RightArea_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    line-height: 150%;
  }

  .Email_RightArea_Buttons_CT {
    display: flex;
    flex-direction: row;
    margin-top: 32px;
  }
  .Email_RightArea_Buttons_CT2 {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
  }

  .Email_RightArea_CT {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    margin-top: 48px;
    height: auto;
  }

  .Email_RightArea_CT2 {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: auto;
  }

  .Email_Issue_Content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .Issue_Terms_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;
    width: 100%;
  }

  .Issue_Resist_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 24px;
    width: 100%;
  }

  .Issue_Conplete_RightArea_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    height: auto;
  }

  .Reject_Reason_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .Reject_Reason_CenterCT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .Reject_Reason_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 100%;
    background-color: rgb(170, 170, 170);
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .Reject_Reason_Button_BG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 92px;
    height: 48px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(231, 239, 255);
    border-radius: 8px;
    background-color: rgb(231, 239, 255);
    cursor: pointer;

    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
  }

  .Issue_Error_Complete_Text_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 24px;
  }

  .Resend_Textinput {
    display: flex;
    width: 95%;
    height: 24px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(70, 70, 70);
    border-radius: 8px;
    padding: 12px;
    resize: none;
    margin-top: 12px;
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }
  .LadingData_Copy {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 48px;
  }
  .Memo_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    margin-bottom: 24px;
  }
}
