@media screen and (min-width: 1024px) {
  input::placeholder {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  textarea::placeholder {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .Make_Seal_Input::placeholder {
    font-size: 16px;
  }

  @keyframes lnb_in {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .Flex_App {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }

  .FlexContent_Login {
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100vh;
  }

  .FlexContent_Other {
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
  }

  .FlexContent_Other_V {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: auto;
  }

  .AllContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .AllContent_Default_C {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 65vw;
    /* margin-top: 64px; */
    /* background-color: aqua; */
    margin-left: 25vw;
  }

  .AllContent_Default_V {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 65vw;
    margin-top: 112px;
    /* background-color: aqua; */
    margin-left: 25vw;
  }

  .AllContent_Between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: auto;
    height: 100vh;
    padding-left: 4vw;
    padding-right: 4vw;
  }

  .Required_Dot_Red {
    width: 6px;
    height: 6px;
    background-color: rgb(216, 44, 44);
    border-radius: 3px;
    margin-left: 8px;
  }

  .Required_Dot_Blue {
    width: 6px;
    height: 6px;
    background-color: rgb(55, 105, 252);
    border-radius: 3px;
    margin-left: 8px;
  }

  .GNB_BG {
    display: flex;
    flex-direction: row;
    position: fixed;
    z-index: 3;
    box-shadow: none;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 48px;
    /* height: 96px; */
    background-color: rgb(255, 255, 255);
    justify-content: space-between;
    align-items: center;
  }

  .GNB_BG_V {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 3;
    box-shadow: none;
    top: 0px;
    left: 0px;
    width: 237px;
    height: 100%;
    /* height: 96px; */
    background-color: rgb(255, 255, 255);
    justify-content: flex-start;
    align-items: flex-start;

    border-style: solid;
    /* border-color: rgb(0, 128, 255); */
    border-color: rgb(242, 244, 246);
    border-right-width: 3px;
    border-left-width: 0px;
    border-top-width: 0px;
    border-bottom-width: 0px;
  }

  .GNB_LeftContents {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    margin-left: 4vw;
  }

  .GNB_LogoCT_V {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    margin-top: 24px;
  }

  .GNB_MenuBar_CT {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-top: 48px;
  }

  .GNB_MenuBar_BG_On {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4px;
    width: 220px;
    height: 40px;
    padding: 0px;
    border-style: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: rgb(231, 239, 255);
  }

  .GNB_MenuBar_BG_Off {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4px;
    width: 220px;
    height: 40px;
    padding: 0px;
    border-style: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
  }

  .GNB_MenuBar_Contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .GNB_MenuBar_Label_On {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    margin-left: 12px;
  }

  .GNB_MenuBar_Label_Off {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    margin-left: 12px;
  }

  .GNB_MenuBar_Divider {
    margin-top: 12px;
    margin-bottom: 16px;
    margin-left: 10px;
    width: 220px;
    height: 2px;
    background-color: rgb(227, 227, 227);
  }

  .GNB_Label_Underline {
    display: flex;
    z-index: 1;
    text-decoration: none;
  }

  .GNB_Logo {
    display: flex;
    z-index: 1;
    width: 78px;
    /* width: 156px; */
    height: 18px;
    /* height: 36px; */
  }

  .GNB_Logo_V {
    display: flex;
    z-index: 1;
    width: 167px;
    /* width: 156px; */
    height: 24px;
    /* height: 36px; */
  }

  .GNB_CompanyProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
  }

  .GNB_CompanyLogo {
    display: flex;
    z-index: 1;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    overflow: hidden;
  }

  .GNB_CompanyName_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;

    margin-left: 8px;
  }

  .GNB_SettingIcon_CT {
    display: flex;
    z-index: 1;
    margin-left: 8px;
    width: 24px;
    height: 24px;
  }

  .GNB_RightContents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
    /* margin-right: 4vw; */
  }

  .GNB_Logout_CT {
    display: flex;
  }

  .GNB_Profile_CT {
    display: flex;
    margin-left: 12vw;
  }

  .GNB_Logout_Button {
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    border: none;
  }

  .Home_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Login_Head_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    letter-spacing: -3%;

    margin-top: 160px;
    margin-bottom: 48px;
  }

  .Login_Auto_Copy {
    color: rgb(121, 121, 121);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -3%;

    margin-left: 2px;
  }

  .Login_TextButton_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -3%;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0px;
    padding: 0px;
  }

  .Login_VerticalLine {
    display: flex;
    width: 1px;
    height: 16px;
    background-color: rgb(170, 170, 170);
    margin-left: 4px;
    margin-right: 4px;
  }

  .Login_Input_BG {
    display: flex;
    flex-direction: row;
    width: 160px;
    height: 32px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(170, 170, 170);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
    outline: none;
  }

  .LoginButton_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 228px;
    height: 64px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
  }

  .Login_Button_One_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
  }

  .LoginButton_One_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 180px;
    height: 44px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
  }

  .LoginButton_Copy_CT {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .LoginButton_Copy {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
  }

  .Login_TextButton_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    width: 320px;
  }

  .Login_RightContents_CT {
    display: flex;
    flex-direction: row;
  }

  .Login_SigninButton_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Login_SigninButton {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 48px;
  }

  .Login_Signin_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Login_SigninButton_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;

    margin-left: 4px;
  }

  .Login_Auto_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Signin_Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: flex-start;
    width: 480px;
    margin-top: 48px; */
  }

  .Signin_InputField_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Signin_ShortInput_BG {
    display: flex;
    flex-direction: row;
    width: 434px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .Signin_TextArea_BG {
    display: flex;
    flex-direction: row;
    width: 434px;
    height: 96px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
    resize: none;
    outline: none;
  }

  .Signin_LongInput_BG_None {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 450px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    outline: none;
  }

  .Signin_LongInput_BG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 462px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .Signin_Line {
    display: flex;
    flex-direction: row;
    width: 452px;
    height: 2px;
    background-color: rgb(227, 227, 227);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .Signin_CompleteModal_BG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .Issue_Seal_Modal_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    max-width: 480px;
  }

  .Issue_Error_Text_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .Issue_Error_Complete_Text_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 24px;
  }

  .Signin_CompleteModal_CT {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    width: 360px;
  }

  .Signin_CompleteModal_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: -3%;
    margin-top: 16px;
  }

  .Signin_CompleteModal_Button_CT {
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .Signin_CompleteModal_Button {
    margin: 0px;
    padding: 0px;
    border: none;
    text-decoration: none;
    background-color: rgb(55, 105, 252);
    border-radius: 12px;
    width: 128px;
    height: 48px;
    cursor: pointer;
  }

  .Signin_CompleteModal_Button_Copy {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .Email_Button {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 48px;
  }

  .Issue_Error_Button {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 24px;
  }

  .Email_Button_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Email_Button_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    letter-spacing: -3%;
  }

  .Email_CT {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .Email_Block_All_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
  }

  .Email_Block_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .Email_Block_Icon_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 240px;
    margin-bottom: 24px;
  }

  .Email_Issue_Content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .Email_LeftArea_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 96px;
    min-width: 720px;
    height: 788px;
  }

  .Issue_Complete_LeftArea_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 96px;
    height: 540px;
  }

  .Email_LeftContents_CT {
    display: flex;
    flex-direction: column;
  }

  .Email_LeftContents_CT_Reject {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Email_Main_1_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    letter-spacing: -3%;
  }

  .Email_Main_2_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    letter-spacing: -3%;
  }

  .Email_Main_Copy_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Email_Main_1_Copy_CT {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 4px;
  }

  .Email_Sub_Copy_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;
  }

  .Email_TextButton_Copy_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .Email_Sub_1_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_Sub_2_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: -3%;
    line-height: 150%;
  }

  .Email_TextButton_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .Email_Sub_Error_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
    margin-bottom: 4px;
  }

  .Email_Sub_ETC_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
    /* margin-top: 16px; */
  }

  .Email_InfoBox_BG {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 30vw;
    height: auto;
    border-style: solid;
    border-radius: 12px;
    border-width: 1px;
    border-color: rgb(246, 250, 255);
    background-color: rgb(246, 250, 255);
    padding: 24px;
  }

  .Email_InfoBox_BG_CT {
    display: flex;
    flex-direction: column;
    margin-top: 36px;
  }

  .Email_InfoBox_1_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Email_InfoBox_1_Copy_CT {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .Email_InfoBox_2_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_InfoIcon_CT {
    width: 60%;
    max-width: 582px;
    max-height: 586px;
    margin-top: 64px;
  }

  .Email_LawInfo_Copy_CT {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    width: 30vw;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding-left: 24px;
    padding-right: 24px;
  }

  .Email_LawInfo_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_LawProfile_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .Email_LawProfileImage_CT {
    width: 64px;
    height: 64px;
  }

  .Email_LawProfile_Left_CT {
    display: flex;
    flex-direction: row;
    margin-right: 24px;
  }

  .Email_LawProfile_Right_CT {
    display: flex;
    flex-direction: column;
  }

  .Email_LawProfile_Intro_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .Email_LawProfile_Company_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_LawProfile_Name_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_LawProfile_Vertical_CT {
    width: 1px;
    height: 16px;
    background-color: rgb(170, 170, 170);
    margin-left: 8px;
    margin-right: 8px;
  }

  .Email_LawProfile_Detail_CT {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }

  .Email_LawProfile_Tel_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_RightArea_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 96px;
    height: auto;
    margin-right: 72px;
  }

  .Email_RightArea_Icon_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 96px;
    height: auto;
  }

  .Issue_Conplete_RightArea_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 96px;
    height: auto;
    margin-right: 80px;
  }

  .Email_PDF_BG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 420px;
    height: 594px;
    background-color: rgb(255, 255, 255);
    padding: 24px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  .Email_PDF_Upper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Email_PDF_Lower {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Email_PDF_CompanyHead_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .Email_CompanyHead_Input_BG {
    display: flex;
    flex-direction: row;
    width: 116px;
    height: 32px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(55, 105, 252);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .Email_PDF_IssueNumber_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjo";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    letter-spacing: -3%;
    width: 420px;
  }

  .Email_PDF_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    word-spacing: 16px;
    margin-top: 48px;
  }

  .Email_PDF_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    margin-top: 32px;
  }

  .Email_PDF_Date_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    margin-top: 12px;
  }

  .Email_PDF_CompanyName_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-top: 32px;
    margin-bottom: 12px;
  }

  .Email_PDF_CompanyHead_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-right: 4px;
  }

  .Email_PDF_Chart_BG {
    width: 420px;
    height: auto;
    border-style: solid;
    border-width: 0.5px;
    border-color: rgb(70, 70, 70);
    background-color: rgb(255, 255, 255);
    margin-top: 32px;
  }

  .Email_PDF_Cell_Label {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    text-align: center;

    width: 38px;
    padding: 8px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(242, 244, 246);
  }

  .Email_PDF_Cell_Label_2 {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    word-spacing: 16px;

    width: 38px;
    padding: 8px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(242, 244, 246);
  }

  .Email_PDF_Cell_Label_3 {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    word-spacing: 1.7px;

    width: 38px;
    padding: 8px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(242, 244, 246);
  }

  .Email_PDF_Cell_Body {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    text-align: left;
    line-height: 150%;

    padding: 8px;
    border: 1px solid rgb(70, 70, 70);
  }

  .Email_PDF_SealNone_BG {
    display: flex;
    position: absolute;
    top: 640px;
    left: 77%;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-style: dashed;
    border-width: 3px;
    border-color: rgb(148, 186, 253);
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
    opacity: 0.8;
    cursor: pointer;
  }

  .Email_PDF_SealDone_BG {
    display: flex;
    position: absolute;
    top: 640px;
    left: 73%;
    align-items: flex-start;
    justify-content: flex-start;

    cursor: pointer;
  }

  .Email_PDF_SealDone_Close_CT {
    display: flex;
    flex-direction: row;
    margin-left: 4px;

    border: none;
    background-color: transparent;
    padding: 0px;
  }

  .Email_PDF_Seal_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    text-align: center;
  }

  .Email_RightArea_Body_CT {
    display: flex;
    flex-direction: column;
    width: 468px;
    margin-top: 24px;
    align-items: flex-start;
  }

  .Email_RightArea_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    line-height: 150%;
  }

  .Email_RightArea_Buttons_CT {
    display: flex;
    flex-direction: row;
    margin-top: 32px;
  }
  .Email_RightArea_Buttons_CT2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 32px;
  }
  .Email_Left_Button_BG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 48px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(255, 155, 155);
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;

    color: rgb(216, 44, 44);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
  }

  .Email_Right_Button_BG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 48px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(55, 105, 252);
    border-radius: 8px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
    margin-left: 16px;

    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
  }

  .Email_Left_Button_BG_Line {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 48px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(55, 105, 252);
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    margin-left: 16px;

    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
  }

  .Read_Labels_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .Read_Label_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }

  .Read_Button {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .Read_Right_Button_BG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 32px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(55, 105, 252);
    border-radius: 8px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
    /* margin-left: 16px; */

    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }

  .Read_Right_Button_Short_BG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 32px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(231, 239, 255);
    border-radius: 8px;
    background-color: rgb(231, 239, 255);
    cursor: pointer;
    /* margin-left: 16px; */

    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }

  .Read_RightArea_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 96px;
    height: auto;
    width: 420px;
    margin-right: 160px;
  }

  .Read_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 100%;
    background-color: rgb(170, 170, 170);
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .Read_Item_Text_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 335px;
    margin-right: 24px;
    /* background-color: aqua; */
  }

  .Read_Item_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 100%;
    background-color: rgb(227, 227, 227);
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .Read_Item_Type_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: left;

    margin-bottom: 4px;
  }

  .Read_Item_Name_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: left;
  }

  .Read_Item_Date_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;

    margin-top: 8px;
  }

  .Read_Item_Positions_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;

    margin-top: 4px;
  }

  .Read_Item_Jobs_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;

    margin-top: 4px;
  }

  .Read_All_Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
  }

  .Read_Content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .Read_Image {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 122px;
    height: 173px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  }

  .Reject_Reason_CT {
    display: flex;
    margin-top: 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Reject_Reason_BG {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 500px;
    /* max-width: 360px; */
    height: auto;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(170, 170, 170);
    border-radius: 8px;
    padding: 12px;
  }

  .Reject_Reason_TextArea {
    display: flex;
    width: 95%;
    height: 240px;
    border-width: 1px;
    border-color: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 12px;
    resize: none;

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Reject_Reason_TextArea2 {
    display: flex;
    width: 95%;
    min-width: 426px;
    height: 120px;
    border-width: 1px;
    border-color: rgb(170, 170, 170);
    border-radius: 8px;
    padding: 12px;
    resize: none;
    margin-top: 12px;

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Resend_Textinput {
    display: flex;
    width: 95%;
    height: 24px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(70, 70, 70);
    border-radius: 8px;
    padding: 12px;
    resize: none;
    margin-top: 32px;
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Reject_Reason_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 100%;
    background-color: rgb(170, 170, 170);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .Reject_Reason_Button_BG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 48px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(231, 239, 255);
    border-radius: 8px;
    background-color: rgb(231, 239, 255);
    cursor: pointer;
    margin-right: 12px;
    margin-bottom: 12px;

    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
  }

  .Issue_Terms_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;
    width: 452px;
  }

  .Issue_Resist_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 24px;
    width: 468px;
  }

  .Issue_Error_BG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    background-color: rgb(255, 255, 255);
  }

  .Email_RightArea_Resist_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    line-height: 150%;
  }

  .Email_RightArea_Resist_Copy2 {
    color: rgb(150, 150, 150);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    line-height: 150%;
    margin-top: 12px;
  }

  .RequestList_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 92vw;
    margin-top: 84px;
  }

  .RequestList_Label_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .RequestList_Labels_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
  }

  .RequestList_Labels_Col_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .RequestList_Label_Name {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: red; */
    width: 84px;
  }

  .RequestList_Body_Name {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: red; */
    width: 84px;
  }

  .RequestList_Label_Date {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(104, 104, 168); */
    width: 80px;
  }

  .RequestList_Body_Date {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(104, 104, 168); */
    width: 80px;
  }

  .RequestList_Label_Type {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(104, 104, 168); */
    width: 32px;
  }

  .RequestList_Body_Type {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(104, 104, 168); */
    width: 32px;
  }

  .RequestList_Label_CompanyName {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 220px;
  }

  .RequestList_Body_CompanyName {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 220px;
    word-break: break-all;
  }

  .RequestList_Body_Email {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 210px;
    word-break: break-all;
  }

  .RequestList_Label_Email {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 210px;
  }

  .RequestList_Body_TelNumber {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 110px;
  }

  .RequestList_Label_TelNumber {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 110px;
  }

  .RequestList_Label_Count {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 54px;
  }

  .RequestList_Body_Count {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 54px;
  }

  .RequestList_Label_Status {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 72px;
  }

  .RequestList_Body_Status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    background-color: rgb(0, 0, 0);
    width: 58px;
    height: 24px;
  }

  .RequestList_Label_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }

  .RequestList_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }

  .RequestList_Label_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 92vw;
    /* min-width: 1282px; */
    max-width: 1282px;
    background-color: rgb(170, 170, 170);
    margin-top: 8px;
  }

  .RequestList_Body_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 92vw;
    /* min-width: 1282px; */
    max-width: 1282px;
    background-color: rgb(227, 227, 227);
    margin-top: 8px;
  }

  .RequestListCT_Contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 92vw;
    /* min-width: 1282px; */
    background-color: rgb(255, 255, 255);
    margin-top: 8px;
  }

  .RequestListCT_Button_BG {
    margin: 0px;
    padding: 0px;
    width: 72px;
    height: 24px;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    border-color: rgb(170, 170, 170);
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
  }

  .RequestListCT_Button_Icon {
    display: flex;
    margin: 0px;
    padding: 0px;
    width: 24px;
    height: 24px;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    border-color: rgb(170, 170, 170);
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  .RequestListCT_Button_S {
    margin: 0px;
    padding: 0px;
    width: 36px;
    height: 24px;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    border-color: rgb(170, 170, 170);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;
  }

  .RequestListCT_Button_L {
    margin: 0px;
    padding: 0px;
    width: 72px;
    height: 24px;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    border-color: rgb(170, 170, 170);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;
  }

  .RequestListCT_IconButton {
    margin: 0px;
    padding: 0px;
    width: 24px;
    height: 24px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;
  }

  .RequestList_Button_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
  }

  .TabBar_CT {
    display: flex;
    flex-direction: column;

    width: 90vw;
    max-width: 1024px;
    margin-bottom: 32px;
    margin-top: 96px;
  }

  .TabBar_Labels_CT {
    display: flex;
    flex-direction: row;
    z-index: 1;
  }

  .TabBar_Label_CT {
    display: flex;
    flex-direction: row;
    z-index: 1;
    text-decoration: none;
    margin-right: 48px;
    cursor: pointer; /* 커서 모양 변경 (선택사항) */
  }

  .TabBar_Label_Underline {
    display: flex;
    z-index: 1;
    text-decoration: none;
    margin: 0px;
    padding: 0px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .TabBar_Label_Copy_A {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .TabBar_Label_Copy_B {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .TabBar_Line {
    display: flex;
    flex-direction: row;
    width: 90vw;
    max-width: 1024px;
    height: 2px;
    background-color: rgb(227, 227, 227);
    margin-top: 12px;
  }

  .DetailTitle_CT {
    display: flex;
    flex-direction: column;

    width: 90vw;
    max-width: 1024px;
    /* margin-bottom: 32px; */
    margin-top: 96px;
  }

  .DetailTitle_Labels_CT {
    display: flex;
    flex-direction: row;
  }

  .DetailTitle_Label_CT {
    display: flex;
    flex-direction: row;
  }

  .DetailTitle_Label_Underline {
    display: flex;
    text-decoration: none;
    background-color: transparent;
    margin: 0px;
    padding: 0px;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
  }

  .DetailTitle_Icon_CT {
    display: flex;
    flex-direction: row;
    width: 24px;
    height: 24px;
  }

  .DetailTitle_Icon_Profile_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-left: 4px;
    /* margin-bottom: 4px; */
  }

  .DetailTitle_Label_Copy_A {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .DetailTitle_Label_Copy_B {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .DetailTitle_Line {
    display: flex;
    flex-direction: row;
    width: 90vw;
    max-width: 1024px;
    height: 2px;
    background-color: rgb(227, 227, 227);
    margin-top: 24px;
  }

  .ListCard_BG {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 90vw;
    max-width: 1024px;
    height: 64px;
    border-style: solid;
    border-radius: 12px;
    border-width: 1px;
    border-color: rgb(148, 186, 253);
    background-color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .ListCard_Box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .ListCard_Label_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
  }

  .ListCard_Body_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;

    margin-top: 4px;
  }

  .ListCard_Box_CT {
    display: flex;
    margin-right: 64px;
  }

  .ListCard_Box_CT_First {
    display: flex;
    margin-left: 32px;
    margin-right: 64px;
  }

  .ListCard_Box_CT_Last {
    display: flex;
    margin-right: 32px;
  }

  .ListCard_LeftContents {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .ListCard_RightContents {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .Wait_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1024px;
  }

  .Wait_Lists_CT {
    display: flex;
    flex-direction: column;
    height: 656px;
  }

  .Wait_NoneItem_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 120px;
    margin-bottom: 584px;
  }

  .Wait_Notification_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 360px;
    margin-bottom: 346px;
  }

  .DetailWait_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1024px;
  }

  .Wait_Pagination {
    display: flex;
    width: auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .Wait_Pagination_Active_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    height: 32px;
  }

  .Wait_Pagination_Inactive_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    height: 32px;
    padding: 0px;
  }

  .Wait_Pagination_Style {
    display: flex;
    list-style-type: none;
    float: left;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 37px;
  }

  .Wait_PaginationCT_Style {
    display: flex;
    list-style-type: none;
    float: left;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 32px;
  }

  .Detail_Title_BG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 1024px;
    background-color: rgb(246, 246, 246);
    height: 64px;
  }

  .Detail_Title_Contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .Detail_Title_LeftContents {
    display: flex;
    flex-direction: row;
    margin-left: 24px;
  }

  .Detail_Title_RightContents {
    display: flex;
    flex-direction: row;
    margin-right: 24px;
  }

  .Detail_Title_ContentBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 48px;
  }

  .Detail_Title_ContentBox_Last {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Detail_Title_Label_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -3%;
    margin-bottom: 4px;
  }

  .Detail_Title_Content_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .CertificateCard_BG {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 16px;
    width: 480px;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin-top: 48px;
  }

  .CertificateCard_BG_Complete {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 16px;
    width: 480px;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin-top: 48px;
  }

  .CertificateCard_BG_Reject {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 16px;
    width: 480px;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin-top: 48px;
  }

  .CertificateCard_Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 16px;
    margin-bottom: 12px;
  }

  .CertificateCard_Body_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .CertificateCard_SecondText_CT {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .CertificateCard_Label_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: -3%;
    margin-left: 24px;
  }

  .CertificateCard_BodyLeft {
    display: flex;
    flex-direction: row;
  }

  .CertificateCard_BodyLeft_C {
    display: flex;
    flex-direction: column;
  }

  .CertificateCard_Body_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: -3%;
    margin-left: 24px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .CertificateCard_SecondBody_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: -3%;
    margin-left: 24px;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  .CertificateCard_DetailBody_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: -3%;
    margin-left: 8px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .CertificateCard_Icon_CT {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    cursor: pointer;
  }

  .CertificateCard_Line {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 1px;
    background-color: rgb(227, 227, 227);
  }

  .DetailWait_Button_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 480px;
    margin-top: 32px;
    margin-bottom: 64px;
  }

  .DetailWait_Button_One_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 64px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    padding: 0px;
  }

  .PrimayButton_One_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 480px;
    height: 64px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
    border: none;
  }

  .PrimayButton_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 228px;
    height: 64px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
  }

  .SubButton_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-radius: 12px;
    border-color: rgb(55, 105, 252);
    border-width: 1px;
    width: 228px;
    height: 64px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;

    margin-top: 24px;
    margin-right: 24px;
  }

  .DestroyButton_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 228px;
    height: 64px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 1px;
    border-color: rgb(255, 115, 115);
    cursor: pointer;
  }

  .PrimayButton_Copy {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
  }

  .SubButton_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
  }

  .DestroyButton_Copy {
    color: rgb(216, 44, 44);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
  }

  .RejectInputBox_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 12px;
    width: 446px;
    height: 400px;
    background-color: rgb(255, 255, 255);
    margin-top: 48px;
    padding: 16px;
    resize: none;
    outline: none;
  }

  .RejectReason_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Reject_Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .RejectReason_Label_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;

    display: flex;
    margin-bottom: 8px;
    margin-top: 32px;
    width: 480px;
  }

  .RejectReason_Body_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;

    display: flex;
    margin-bottom: 64px;
    width: 480px;
  }

  .Profile_Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 640px;
    margin-top: 48px;
  }

  .Profile_Subject {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 24px;
    width: 640px;
  }

  .Profile_Subject_Last {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 640px;
  }

  .ProfileDetail_Subject {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 24px;
    width: 480px;
    height: auto;
  }

  .ProfileDetail_Subject_Last {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 452px;
  }

  .Profile_Label_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
    width: 452px;
  }

  .Profile_Label_Preview_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
  }

  .Profile_Label_Icon_Logo_CT {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0px;
  }

  .Profile_Label_Icon_License_CT {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    cursor: pointer;
  }

  .Profile_Label_Icon_Seal_CT {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0px;
  }

  .Profile_Label_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .Profile_Preview_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-decoration: underline;
    text-decoration-color: rgb(55, 105, 252);
    border: none;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
  }

  .Profile_Body_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    width: 640px;
  }

  .Profile_NoImage_Copy {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .Profile_Placeholder_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
  }

  .Profile_Line {
    display: flex;
    flex-direction: row;
    width: 640px;
    height: 2px;
    background-color: rgb(227, 227, 227);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .Profile_CloseModal_Button_CT {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .Profile_CloseModal_Button {
    margin: 0px;
    padding: 0px;
    border: none;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
  }

  .Profile_CloseModal_Contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .ProfileDetail_ShortInput_BG {
    display: flex;
    flex-direction: row;
    width: 366px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .ProfileDetail_LongInput_BG {
    display: flex;
    flex-direction: row;
    width: 624px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    padding-left: 8px;
    padding-right: 8px;
  }

  .ProfileDetail_LongInput_BG_Icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 640px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
  }

  .ProfileDetail_LongInput_None {
    display: flex;
    flex-direction: row;
    border: none;
    width: 624px;
    height: 40px;
    background-color: transparent;
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .ProfileDetail_AddressDetail_CT {
    display: flex;
    align-items: center;
    margin-top: 12px;
  }

  .ProfileDetail_TextArea_BG {
    display: flex;
    flex-direction: row;
    width: 624px;
    height: 96px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
    resize: none;
  }

  .ProfileDetail_UploadButton_CT {
    display: flex;
    justify-content: row;
    margin-bottom: 12px;
  }

  .ProfileDetail_UploadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 44px;
    border-radius: 8px;
    border-width: 0px;
    background-color: rgb(231, 239, 255);

    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    margin-left: 12px;
    cursor: pointer;
    padding: 0px;
  }

  .ProfileDetail_UploadButton_None {
    display: none;
  }

  .ProfileDetail_Image {
    width: 120px;
  }

  .Seal_Image_Modal {
    width: auto;
    max-height: 640px;
  }

  .ImgModal_CT {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    padding-top: 240px;
    width: 100vw;
    height: auto;
  }

  .ImgModal_Button {
    display: flex;
    flex-direction: column;
    width: 32px;
    height: 32px;
    margin-left: 8px;
    cursor: pointer;
  }

  .Icon_CT {
    display: flex;
    flex-direction: column;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    cursor: pointer;
  }

  .PdfModal_CT {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    padding-top: 240px;
    width: 100vw;
    height: auto;
  }

  .PdfModal_Close {
    display: flex;
    flex-direction: column;
    width: 32px;
    height: 32px;
    margin-left: 8px;
    cursor: pointer;
  }

  .Footer_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 96px;
    margin-bottom: 24px;
    width: 65vw;
  }

  .Footer_Contents_CT {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
  }

  .Footer_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 65vw;
    background-color: rgb(170, 170, 170);
    /* max-width: 1024px; */
  }

  .Footer_Button {
    display: flex;
    margin: 0px;
    padding: 0px;
    border: none;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
  }

  .Footer_Logo {
    display: flex;
    width: 79.5px;
    height: 18px;
  }

  .Footer_Texts {
    display: flex;
    flex-direction: column;
    margin-left: 48px;
  }

  .Footer_Text {
    display: flex;
    margin-bottom: 4px;
  }

  .Footer_Text_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* justify-content: center; */
  }

  .Signin_Modal_BG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Profile_Modal_BG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
  }

  .Modal_Contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .Modal_Icon {
    display: flex;
    flex-direction: column;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    text-decoration: none;
  }

  .Signin_Password_Contents {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Signin_PasswordInvalid_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
    margin-top: 8px;
  }

  .Signin_Error_Copy {
    color: rgb(255, 115, 115);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
    margin-top: 8px;
  }

  .Signin_Match_Copy {
    color: rgb(3, 166, 0);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
    margin-top: 8px;
  }

  .Signin_Guide {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 452px;
  }

  .Signin_Guide_TextButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    /* text-decoration: none; */
    cursor: pointer;

    margin-top: 8px;
  }

  .Signin_Guide_TextButton_Icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
  }

  .Signin_Guide_TextButton_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
  }

  .Signin_IdEmail_Num {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .TermLists_BG {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 452px;
    height: auto;

    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 8px;

    padding-bottom: 8px;
  }

  .TermLists_Short_BG {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 266px;
    height: auto;

    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 8px;

    padding-bottom: 8px;

    margin-bottom: 24px;
  }

  .TermLists_Line {
    width: 432px;
    height: 1px;
    background-color: rgb(227, 227, 227);
  }

  .TermLists_Short_Line {
    width: 92%;
    min-width: 240px;
    height: 1px;
    margin-bottom: 12px;
    background-color: rgb(227, 227, 227);
  }

  .TermList_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 432px;
    height: 32px;
  }

  .TermList_Two_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 432px;
    height: 48px;
  }

  .TermList_Short_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 92%;
    min-width: 240px;
    height: 32px;
  }

  .TermList_Two_Short_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 92%;
    min-width: 240px;
    height: 48px;
  }

  .TermList_Left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .TermList_Right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .TermList_Default_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
  }

  .TermList_Optional_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    margin-right: 2px;
  }

  .TermList_TwoLine_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .TermList_Red_Copy {
    color: rgb(255, 115, 115);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    margin-right: 2px;
  }

  .TermList_Detail_CT {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 8px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .TermList_Detail_Icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }

  .TermList_Detail_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
  }

  .TermList_CheckBox_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .Read_AllContent_Between {
    display: flex;
    flex: 1;
    height: auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 48px;
    padding-left: 4vw;
    padding-right: 4vw;
    background-color: rgb(255, 255, 255);
  }

  .LadingData_Copy {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 48px;
  }

  .Memo_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    margin-bottom: 24px;
  }

  .Leave_Guide_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    line-height: 150%;
    margin-top: 120px;
  }

  .Leave_Image_CT {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
    width: auto;
  }

  .Leave_TextButton_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 72px;
    width: 100%;
    max-width: 600px;
  }

  .Leave_TextButton_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: -3%;

    padding: 0px;
    border-style: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
  }

  .Leave_IconTextButton_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .Leave_LeftArea_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 688px;
    /* background-color: aquamarine; */
  }

  .Leave_InputField_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 480px;
    margin-top: 48px;
  }

  .Leave_Main_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    letter-spacing: -3%;
    line-height: 150%;
  }

  .Leave_Sub_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: -3%;
    line-height: 150%;

    margin-top: 24px;
    max-width: 480px;
  }

  .Leave_LongInput_BG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 480px;
    height: 48px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .Leave_Vertical_Divider {
    width: 1px;
    height: 80%;
    min-height: 640px;
    background-color: rgb(227, 227, 227);
  }

  .Leave_RightArea_CT {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    max-width: 242px;
    height: 640px;
    /* background-color: rgb(245, 255, 140); */
  }

  .Leave_PageCount_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: -3%;
  }

  .Leave_Messege_BG {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 480px;
    height: 340px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
  }

  .Leave_Messege_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .Leave_Messege_Label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .LNB_BG {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 3;
    box-shadow: none;
    top: 0px;
    right: 0px;
    width: 200px;
    height: 100vh;
    /* height: 96px; */
    background-color: rgb(255, 255, 255);
    box-shadow: -2px 0px 12px 4px rgb(0, 0, 0, 0.1);
    justify-content: flex-start;
    align-items: center;
    animation: lnb_in 0.2s ease-in;
  }

  .LNB_BG_Dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 998;
  }

  .LNB_Icon_CT {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 176px;
    height: 24px;
    margin-top: 16px;
    /* background-color: aqua; */
  }

  .LNB_Name_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
    margin-top: 24px;
    /* background-color: aquamarine; */
    word-break: break-all;
    width: 176px;
  }

  .LNB_CompanyName_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
    margin-top: 8px;
    width: 176px;
    line-height: 130%;
    /* background-color: brown; */
    word-break: break-all;
  }

  .LNB_Menu_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
    line-height: 130%;
    margin-left: 8px;
    /* background-color: rgb(139, 131, 131); */
  }

  .LNB_Menu_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    /* background-color: aqua; */
  }

  .LNB_MenuButton {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    width: 176px;
    height: 48px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .LNB_MenuContents_BG {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 176px;
    height: 48px;
    /* background-color: bisque; */
  }

  .LNB_MenuIcon_CT {
    display: flex;
    width: 24px;
    height: 24px;
    /* background-color: blueviolet; */
  }

  .LNB_Divider_BG {
    width: 176px;
    height: 1px;
    background-color: rgb(170, 170, 170);
  }

  .LNB_Menu_BG {
    display: flex;
    flex-direction: column;
  }

  .Make_Seal_Modal_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 416px;
    padding: 32px;
    background-color: white;
    border-style: none;
    border-radius: 12px;
  }

  .Make_Seal_Title_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .Make_Seal_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .Make_Seal_Icon_CT {
    width: 32px;
    height: 32px;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
  }

  .Make_Seal_Divider {
    width: 100%;
    height: 1px;
    margin-top: 12px;
    margin-bottom: 32px;
    background-color: rgb(170, 170, 170);
  }

  .Make_Seal_Input {
    display: flex;
    flex-direction: row;
    width: 314px;
    height: 38px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 12px;
    outline: none;
  }

  .Make_Seal_GenerateButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 42px;
    padding: 0px;
    margin: 0px;
    background-color: rgb(231, 239, 255);
    border-style: none;
    border-radius: 8px;
    cursor: pointer;

    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .Make_Seal_Input_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .Make_Seal_Info_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 150%;
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .Make_Seal_Canvas_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Make_Seal_DownloadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 64px;
    padding: 0px;
    margin: 0px;
    background-color: rgb(55, 105, 252);
    border-style: none;
    border-radius: 8px;
    cursor: pointer;

    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }

  .Pagination_CT {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }

  .Pagination_ActivePage_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: transparent;
    border-style: none;
    width: 32px;
    cursor: pointer;
  }

  .Pagination_InactivePage_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: transparent;
    border-style: none;
    width: 32px;
    cursor: pointer;
  }

  .Pagination_ArrowButton_CT {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
  }

  .Input_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  .Input_Label_CT {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
  }

  .Input_Label {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }

  .Input_Label_Required_Dot {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    margin-left: 4px;
  }

  .Input_Short_BG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 302px;
    height: 34px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .Input_Short_01_BG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 218px;
    height: 34px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 8px;
    padding-right: 8px;
    outline: none;

    margin-bottom: 0px;
    margin-right: 13px;
  }
  .Input_Short_BG_Y {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 34px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 4px;
    outline: none;
  }

  .Input_Short_BG_MD {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 34px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 8px;
    padding-right: 8px;

    margin-right: 4px;
    outline: none;
  }

  .Input_Short_Row_CT {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .InputShort_Unit {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-left: 8px;
    text-align: left;
  }

  .Input_Feedback_Text_Error {
    color: #ff7373;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
  }

  .Input_Short_Button_Disabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 36px;
    border-radius: 8px;
    border-style: none;
    background-color: #e3e3e3;
    color: #aaaaaa;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    margin-left: 12px;
  }

  .Input_Short_Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 36px;
    border-radius: 8px;
    border-style: none;
    background-color: #e7efff;
    color: #3769fc;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    margin-left: 12px;
  }

  .Input_Feedback_Text_Confirm {
    color: #03a600;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
  }

  .Display_None {
    display: none;
  }

  .Create_Seal_Image_Button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 36px;
    border-radius: 8px;
    border-style: none;
    background-color: #03a600;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
  }

  .Create_Seal_Image_Button_Label_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Create_Seal_Image_Button_Copy {
    color: #ffffff;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }

  .Create_Seal_Image_Button_Icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .CreateSeal_Modal_BG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .CreateSeal_Modal_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 416px;
    padding: 32px;
    background-color: white;
    border-style: none;
    border-radius: 12px;
  }

  .CreateSeal_Title_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .CreateSeal_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .CreateSeal_Icon_CT {
    width: 32px;
    height: 32px;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
  }

  .CreateSeal_Divider {
    width: 100%;
    height: 1px;
    margin-top: 12px;
    margin-bottom: 32px;
    background-color: rgb(170, 170, 170);
  }

  .CreateSeal_Input_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .CreateSeal_Input {
    display: flex;
    flex-direction: row;
    width: 314px;
    height: 38px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 12px;
    outline: none;
  }

  .CreateSeal_GenerateButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 42px;
    padding: 0px;
    margin: 0px;
    background-color: rgb(231, 239, 255);
    border-style: none;
    border-radius: 8px;
    cursor: pointer;

    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .CreateSeal_Info_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 150%;
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .CreateSeal_Canvas_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .CreateSeal_DownloadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 64px;
    padding: 0px;
    margin: 0px;
    background-color: rgb(55, 105, 252);
    border-style: none;
    border-radius: 8px;
    cursor: pointer;

    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }

  .Link_Title_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Link_PageDescription_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }

  .Link_PageDescription_Divider {
    width: 100%;
    height: 1px;
    background-color: rgb(170, 170, 170);
    margin-top: 12px;
    margin-bottom: 32px;
  }

  .Link_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
  }

  .Link_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    margin-top: 16px;
    margin-bottom: 48px;
  }

  .Link_Button_CT {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 36px;
    background-color: #3769fc;
    margin-top: 32px;
    margin-bottom: 24px;
    border-style: none;
    border-radius: 8px;
    cursor: pointer;

    color: #ffffff;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

  .Link_TextButton_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-style: none;
    cursor: pointer;

    color: #3769fc;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .Link_RequiredDocument_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

  .Link_RequiredDocument_List_BG {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background-color: #f2f4f6;
    border-radius: 8px;
    padding: 12px;
    margin-top: 8px;
    margin-bottom: 24px;
    width: 320px;
    box-sizing: border-box;
  }

  .Link_RequiredDocument_List_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    white-space: pre-line;
    line-height: 150%;
  }
}
